<template>
    <div class="certificateView">
        <div class="mainbox">
            <searchs @chooseList="getChooseList" @majorName="getMajorName" class="banner" />
            <connects :chooseList="chooseList" :majorName="majorName" class="banner" />
        </div>


    </div>
</template>

<script>
import {
    getProjectClassList,
    getProjectClassLis,
    selectInformationPcList,
} from "@/api/home";
import { getDomain } from "@/api/cookies";
import searchs from "../components/searchgraduatezy.vue";
import connects from "./connectgraduate.vue";


export default {
    components: { searchs, connects },
    data() {
        return {
            is_huadong: true,
            chooseList: [],
            majorName: ''
        };
    },
    created() {
        // this.detail_id = this.$route.query.id;
        // this.getList();
    },
    mounted() { },
    methods: {
        getChooseList(item) {
            this.chooseList = item
        },
        getMajorName(item) {
            this.majorName = item
        },

    },
};
</script>

<style lang="less" scoped>
.certificateView {
    background: #F5F7F9;

    .mainbox {
        width: 1200px;
        margin: 0 auto;
        // padding: 20px 0;
        margin-bottom: 10px;
    }

    .banner {
        margin-top: 20px;
    }
}
</style>