<template>
    <div class="mainbox">
        <div>
            <div @click="goNext(`/gxGather/detailIndexgraduate?majorId=${item.majorId}`)"
                v-for="(item, index) in majorList" :key="index" class="c_item">
                <!-- <div class="mojor_img">
                    <img src="~@/assets/img/TechnicalCollege/69.png" alt="" />
                </div> -->
                <div class="mojor_detail">
                    <div class="mojor_til">
                        <div class="point"></div>{{ item.majorName }}
                    </div>
                    <div class="mojor_item">
                        <div>
                            <div>所属学科：</div>
                            <div style="width: 120px" class="elp">{{ item.firstSubject }}</div>
                        </div>
                        <div>
                            <div>证书：</div>
                            <div style="width: 155px" class="elp">{{ item.certificate }}</div>
                        </div>
                        <div>
                            <div>专业简介：</div>
                            <div style="width: 740px" v-html="ImgSizeLimitclear(item.majorIntroductory)"
                                class="twoEllipsis">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mojor_btn">
                    <div>查看详情</div>
                </div>
            </div>
            <el-empty v-if="!majorList || majorList.length == 0" description="暂无数据"></el-empty>

        </div>
        <el-pagination style="display: flex; justify-content: center" class="pagination partner-pagination"
            prev-text="上一页" next-text="下一页" :current-page="pageNum" :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next" :total="total" :page-count="4"
            @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
import searchs from "../components/search.vue";
import connects from "./connect.vue";

export default {
    components: { searchs, connects },
    props: {
        chooseList: [],
        majorName: ''
    },
    data() {
        return {
            is_huadong: true,
            pageSize: 12,
            pageNum: 1,
            total: 0,
            majorList: []
        };
    },
    created() {
        // this.detail_id = this.$route.query.id;
        this.getList();
    },
    watch: {
        chooseList: {
            deep: true,
            handler(val) {
                this.getList()
            },
        },
        majorName: {
            deep: true,
            handler(val) {
                this.getList()
            },
        },
    },
    mounted() { },
    methods: {
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getList();
        },
        getList() {
            let projectCategoryId = ''
            let firstSubject = ''
            let secondSubject = ''
            let regularMajorId = ''
            let zkMajorId = ''
            if (this.chooseList && this.chooseList.length > 0) {
                this.chooseList.forEach(e => {
                    if (e.sname == 'firstSubjectList') {
                        firstSubject = e.name
                    } else if (e.sname == 'secondSubjectList') {
                        secondSubject = e.name
                    }
                });
            }
            know
                .getGraduateTuitionPcList({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectType: 1,
                    projectCategoryId: projectCategoryId ? projectCategoryId : undefined,
                    firstSubject: firstSubject ? firstSubject : undefined,
                    secondSubject: secondSubject ? secondSubject : undefined,
                    regularMajorId: regularMajorId ? regularMajorId : undefined,
                    zkMajorId: zkMajorId ? zkMajorId : undefined,
                    majorName: this.majorName ? this.majorName : undefined,

                })
                .then((res) => {
                    this.majorList = res.rows
                    this.total = res.total
                });
        },
    },
};
</script>

<style lang="less" scoped>
.mainbox {
    background: #ffffff;
    border-radius: 3px 3px 3px 3px;
    margin-top: 20px;

    >div {
        display: block;

        .c_item {
            padding: 30px 20px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            cursor: pointer;
            border-bottom: 1px solid #EEEEEE;

            .mojor_img {
                width: 203px;
                height: 132px;
                border-radius: 8px 8px 8px 8px;
                overflow: hidden;
            }

            .mojor_detail {
                width: 880px;
                margin-left: 20px;

                .mojor_til {
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                    font-weight: bold;
                    font-size: 20px;
                    color: #333333;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;

                    .point {
                        width: 10px;
                        height: 10px;
                        background: #3D6CC8;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }

                .mojor_item {
                    display: flex;
                    flex-wrap: wrap;

                    >div {
                        display: flex;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #666666;
                        margin-right: 20px;
                        margin-top: 13px;

                        >div:nth-child(1) {
                            flex-shrink: 0;
                            color: #555555;
                            margin-right: 5px;
                            font-weight: bold;
                        }
                    }
                }
            }

            .mojor_btn {
                height: 90px;
                border-left: 1px solid #eeeeee;
                display: flex;
                align-items: center;

                >div {
                    width: 112px;
                    height: 40px;
                    background: #1061ff;
                    border-radius: 20px 20px 20px 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 14px;
                    color: #ffffff;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 34px;

                    &:hover {
                        background: #3d6cc8;
                    }
                }
            }

            &:hover {
                background: rgba(16, 97, 255, 0.1);

                .mojor_til {
                    color: #3d6cc8;
                }
            }
        }
    }
}

img {
    width: 100%;
    height: 100%;
    display: block;
}
</style>